import { Injectable } from '@angular/core';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtmInterceptorService {
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
      .subscribe(() => {
        this.addUtmParamsToNavigation();
      });
  }

  private addUtmParamsToNavigation() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
    };

    if (
      utmParams.utm_source ||
      utmParams.utm_medium ||
      utmParams.utm_campaign
    ) {
      sessionStorage.setItem('utm_source', utmParams.utm_source || '');
      sessionStorage.setItem('utm_medium', utmParams.utm_medium || '');
      sessionStorage.setItem('utm_campaign', utmParams.utm_campaign || '');
    }

    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.mergeUtmParams();
      });
  }

  private mergeUtmParams() {
    const utmParams = {
      utm_source: sessionStorage.getItem('utm_source'),
      utm_medium: sessionStorage.getItem('utm_medium'),
      utm_campaign: sessionStorage.getItem('utm_campaign'),
    };

    if (
      utmParams.utm_source ||
      utmParams.utm_medium ||
      utmParams.utm_campaign
    ) {
      const currentUrl = this.router.url;
      const queryParams: NavigationExtras = {
        queryParams: {
          utm_source: utmParams.utm_source,
          utm_medium: utmParams.utm_medium,
          utm_campaign: utmParams.utm_campaign,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      };

      this.router.navigate([], queryParams).then(() => {
        sessionStorage.removeItem('utm_source');
        sessionStorage.removeItem('utm_medium');
        sessionStorage.removeItem('utm_campaign');
      });
    }
  }
}
